import React, {useState} from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Wrappers/SideBar"
import { graphql } from "gatsby";
import BlogCard from "../components/Cards/BlogCard";
import SEO from "../components/SEO";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/sections.scss";
import "../styles/general.scss"

export default function Matematik({ data }) {
  const { posts } = data.blog;
  const [currentIndex, setCurrentIndex] = useState(1);
  const elementPerPage = 8;

  function loadMore() {
    setCurrentIndex(currentIndex + 1);
  }

  // Logic for displaying current posts
  const indexOfLastElement = currentIndex * elementPerPage;
  const currentElements = posts.slice(0, indexOfLastElement);

  return (
    <>
      <SEO title={"Artiklar om matematik"}/>
      <Navbar />
      <section className="mainSection sectionMaxWidth">
        <div className="mainWrapper">
        <br/>
            <h1>Artiklar om matematik</h1>
            <p>Här nedan hittar du våra artiklar om matematik.</p>
          {currentElements &&
            currentElements.map((post) => {
              return (
                <article key={post.id}>
                  <BlogCard
                    title={post.frontmatter.title}
                    text={post.excerpt}
                    image={post.frontmatter.featuredImage?.childImageSharp.fluid}
                    link={"/" +post.frontmatter.tag + "/" + post.frontmatter.pathname + "/"}
                    tag={post.frontmatter.tag}
                  />
                </article>
              );
            })}
             {currentIndex * elementPerPage < posts.length && (
                <div className="centerLoadMore">
                  <br />
                  <button className="loadMoreBtn" onClick={loadMore}>
                    Ladda fler{" "}
                  </button>
                </div>
              )}
        </div>
        <Sidebar/>
        
      </section>
      <Footer dark={true} />
    </>
  );
}

export const pageQuery = graphql`
  query math {
    blog: allMarkdownRemark(
    filter: { frontmatter: {tag: { eq: "matematik" } }},
      sort: { fields: [frontmatter___date], order: DESC },
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          tag
          pathname
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        excerpt
        id
      }
    }
  }
`;
